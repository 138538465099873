<template>
  <div class="animated fadeIn">
    <b-button
      size="md"
      variant="primary"
      class="mb-3"
      @click="goBack"
    >
      <i class="icon-arrow-left small"></i> Назад
    </b-button>
    <b-card no-body class="mx-auto text-left">
      <b-card-body class="p-4">
        <div v-if="isPageLoading" class="text-center">
          <i class="fa fa-cog fa-spin fa-4x"></i>
        </div>
        <b-form v-if="!isPageLoading">

          <b-form-group label="Фото на паспорт" :inline="true">
            <b-input-group-prepend>
              <img
                v-if="avatar"
                v-bind:src="avatar"
                width="100"
                height="133"
                @click="toggleCropShow"
              />
              <b-button
                v-if="!isHasPassport()"
                class="ml-2"
                variant="btn-ghost-primary"
                style="z-index: 0"
                @click="toggleCropShow"
              >
                Загрузить новое
              </b-button>
              <crop-upload
                field="img"
                @crop-success="cropSuccess"
                @crop-upload-success="cropUploadSuccess"
                @crop-upload-fail="cropUploadFail"
                v-model="cropShow"
                :width="300"
                :height="400"
                :noCircle="true"
                langType="ru"
                :url="baseURL + 'images/uploadAvatar'"
                :params="cropParams"
                img-format="png"
              ></crop-upload>
            </b-input-group-prepend>
            <small v-if="!isHasPassport()" class="form-text text-muted"
              >Необходимо выбрать и аккуратно обрезать фото, так как оно будет
              размещено на Вашем игровом паспорте.
            </small>
            <small v-if="isHasPassport()" class="form-text text-muted">
              Редактирование фото на паспорт невозможно, т.к. у Вас уже есть
              паспорт.
            </small>
          </b-form-group>

          <b-form-group label="Костюм" description="" :inline="true">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="icon-mustache"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <multiselect
                v-model="selectedSuit"
                :options="suits"
                placeholder="Выберите костюм"
                class="form-control"
                :multiple="false"
                :showNoResults="false"
                :allow-empty="false"
                :showLabels="false"
                :disabled="isConfirmedRequest()"
                label="title"
                return="id"
                track-by="id"
              >
              </multiselect>
            </b-input-group>
            <small v-if="isConfirmedRequest()" class="form-text text-muted">
              Изменение костюма недоступно, т.к. Вы уже имеете подтвержденную
              заявку на ближайшее мероприятие
            </small>
          </b-form-group>

          <b-form-group label="Квента персонажа" :inline="true">
            <textarea
              v-model.trim="$v.editedMember.legend.$model"
              @input="()=>markTouched('legend')"
              :disabled="isConfirmedRequest()"
              class="form-control"
              id="legend"
              name="legend"
              style="min-height: 56px"
              rows="5"
              placeholder="Опишите историю вашего персонажа"
            >
            </textarea>
            <small v-if="!isConfirmedRequest()" class="form-text text-muted">
              Не оставляйте своего персонажа без квенты
            </small>
            <div class="invalid-error" v-if="$v.editedMember.legend.$error">
              <span v-if="!$v.editedMember.legend.required"
                >Это обязательное для заполнения поле.</span
              >
              <span v-if="!$v.editedMember.legend.minLength"
                >Квента должена состоять минимум из
                {{ $v.editedMember.legend.$params.minLength.min }}
                символов.</span
              >
              <span v-if="!$v.editedMember.legend.maxLength"
                >Квента должена состоять максимум из
                {{ $v.editedMember.legend.$params.maxLength.max }} символов.
                Имейте совесть, уменьшите немного размер.</span
              >
            </div>
            <small v-if="isConfirmedRequest()" class="form-text text-muted">
              Изменение квенты недоступно, т.к. Вы уже имеете подтвержденную
              заявку на ближайшее мероприятие
            </small>
          </b-form-group>

          <b-form-group
            label="Несколько фото в экипировке"
            :inline="true"
            v-if="!isConfirmedRequest()"
          >
            <b-input-group-prepend>
              <div
                class="images-upload"
                style="display: flex; justify-content: center;"
              >
                <vue-upload-multiple-image
                  @upload-success="uploadImageSuccess"
                  @before-remove="beforeRemove"
                  @edit-image="editImage"
                  @data-change="dataChange"
                  @size-overflowed="tooBigImageError"
                  :data-images="photos"
                  dragText="Перетащите фото"
                  browseText="или нажмите сюда"
                  popupText="загрузите изображение"
                  dropText="Отпустите здесь"
                  accept="image/jpeg,image/png,image/bmp,image/jpg"
                  :show-primary="false"
                  :multiple="true"
                  :max-image="4"
                  :max-size="1000000"
                ></vue-upload-multiple-image>
              </div>
            </b-input-group-prepend>

            <small class="form-text text-muted" v-if="!isConfirmedRequest()">
              Прикрепляйте СВОИ фото. Каждое фото должно быть размером
              <b>до 1МБ</b>.
            </small>
          </b-form-group>

          <small v-else class="form-text text-muted mb-3">
            Изменение фотографий на допуск недоступно, т.к. Вы уже имеете подтвержденную
            заявку на ближайшее мероприятие
          </small>

          <b-button
            variant="success"
            @click="saveProfile()"
            :disabled="
              $v.$invalid || !Object.keys(touchedFields).length ||
                editedMember.photos_ids.length === 0 ||
                isConfirmedRequest() "
          >
            Сохранить изменения
          </b-button>
        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import API from "../../api/stalk.net";
import Multiselect from "vue-multiselect";
import vSelect from "vue-select";
import PatternInput from "vue-pattern-input";
import VueCoreImageUpload from "vue-core-image-upload";
import CropUpload from "vue-image-crop-upload";
import VueGallery from "vue-gallery";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import {
  integer,
  maxLength,
  minLength,
  required
} from "vuelidate/lib/validators";

export default {
  name: "ProfileEdit",
  components: {
    Multiselect,
    vSelect,
    PatternInput,
    VueCoreImageUpload,
    "crop-upload": CropUpload,
    gallery: VueGallery,
    VueUploadMultipleImage
  },
  data() {
    return {
      isPageLoading: false,
      baseURL: API.baseURL,
      touchedFields: {},
      editedMember: {
        legend: "",
        suit_id: 0,
        passport_photo_id: 0,
        photos_ids: []
      },
      currentMember: {},
      cropShow: false,
      cropParams: {
        token: "",
        name: "avatar"
      },
      avatar: null,
      photos: [],
      photosIndex: null,
      suits: [],
      selectedSuit: {},
      event: {},
      memberEvent: null
    };
  },
  computed: {},
  validations: {
    editedMember: {
      legend: {
        required,
        minLength: minLength(25),
        maxLength: maxLength(1800)
      },
      suit_id: {
        required,
        integer
      },
      passport_photo_id: {
        required,
        integer
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Профиль"})
    },
    loadData() {
      let self = this;
      self.isPageLoading = true;

      self.$store.state.dataLoading
        .then(() => {
          self.currentMember = this.$store.state.member.find(el=> +el.Id === +self.$route.params.id);
          //fill fields
          self.editedMember.legend = self.currentMember.Legend;
          self.editedMember.suit_id = self.currentMember.SuitId;
          self.editedMember.passport_photo_id =
            self.currentMember.PassportPhotoId;
          self.selectedSuit = {
            id: self.currentMember.Suit.Id,
            title: self.currentMember.Suit.Title,
            desc: self.currentMember.Suit.Desc
          };
          self.avatar = self.baseURL + self.currentMember.PassportPhoto.Path;

          self.editedMember.photos_ids = [];
          self.photos = [];
          self.currentMember.Photos.forEach(photo => {
            self.editedMember.photos_ids.push(photo.Id);
            self.photos.push({ path: self.baseURL + photo.Path });
          });

          return API.public.loadSuits();
        })
        .then(resp => {
          this.suits = resp.data;
          return API.public.findActiveRegEvent();
        })
        .then(resp => {
          if (resp.data) {
            self.event = resp.data;
            return API.private.getMembersEventRequest(
              self.event.Id,
              self.currentMember.Id
            );
          }
          return Promise.reject();
        })
        .then(resp => {
          self.memberEvent = resp.data;
          this.isPageLoading = false;
          this.$v.$reset();
        })
        .catch(e => {
          this.catchError(e);
          this.isPageLoading = false;
        });
    },
    tooBigImageError(size) {
      console.error(size);
      this.showError({ message: "Изображение превышает 1МБ размером" });
    },
    uploadImageSuccess(formData) {
      // Upload image api
      API.public
        .uploadImages(formData)
        .then(response => {
          // this.reg.photos_ids = [];
          // this.photos = [];
          let res = response.data;
          if (res) {
            if (res.error && res.error !== "") {
              let e = res.error + " - " + res.description;
              console.error(e);
              self.showError({ message: e });
              return;
            }
            if (res.length === 0) {
              let e = "There is no images in response: " + res;
              console.error(e);
              self.showError({ message: e });
              return;
            }
            res.forEach(img => {
              if (img.img_id && img.img_id > 0) {
                this.editedMember.photos_ids.push(img.img_id);
              }
              // if (img.img_src && img.img_src !== "") {
              //   this.photos.push(img.img_src)
              // }
            });
            this.markTouched('photos_ids')
          }
        })
        .catch(e => {
          console.error(e);
          this.showError({ message: e });
          this.isPageLoading = false;
        });
    },
    beforeRemove(index, done) {
      let self = this;
      self.editedMember.photos_ids = self.editedMember.photos_ids.filter(
        function(value, idx) {
          return index !== idx;
        }
      );

      done();
    },
    editImage() {
      //console.log('edit data', formData, index, fileList)
    },
    dataChange() {
      //console.log(data)
    },
    toggleCropShow() {
      if (!this.isHasPassport()) {
        this.cropShow = !this.cropShow;
      }
    },
    cropSuccess(imgDataUrl) {
      this.avatar = imgDataUrl;
    },
    cropUploadSuccess(jsonData) {
      this.editedMember.passport_photo_id = jsonData.img_id;
      this.markTouched('passport_photo_id')
    },
    cropUploadFail(status, field) {
      let e = "upload fail - " + status + ", field: " + field;
      console.error(e);
      this.showError({ message: e });
    },
    getRank(rank) {
      return API.getRankTitle(rank);
    },
    selectedMemberImages() {
      let self = this;
      let images = [];
      self.currentMember.Photos.forEach(photo => {
        images.push(self.baseURL + photo.Path);
      });

      return images;
    },
    markTouched(fieldName) {
      this.touchedFields[fieldName] = this.editedMember[fieldName]
    },
    saveProfile() {
      let self = this;
      self.isPageLoading = true;
      API.private
        .updateMember(self.currentMember.Id, self.touchedFields)
        .then(response => {
          self.currentMember = response.data;
          // self.$store.commit("setMember", response.data);
          return self.$store.dispatch("loadMember");
        })
        .then(() => {
          self.isPageLoading = false;
          self.loadData();
          self.showSuccess();
        })
        .catch(e => {
          this.catchError(e);
          this.isPageLoading = false;
        });
    },
    isHasPassport() {
      return this.memberEvent && this.memberEvent.HasPassport;
    },
    isConfirmedRequest() {
      return (
        this.memberEvent &&
        (this.memberEvent.Confirmation === API.consts.Request.Approved ||
          this.memberEvent.Confirmation === API.consts.Request.Paid ||
          this.memberEvent.Confirmation === API.consts.Request.PartiallyPaid)
      );
    },
    catchError(e) {
      if (!e) return;

      let self = this;

      console.error(e);
      if (e.response && e.response.data && e.response.data.error) {
        console.error(e.response.data);
        switch (e.response.data.error) {
          case "ERR_BAD_JWT":
          case "ERR_BAD_AUTH":
          case "ERR_NOT_ALLOWED":
            {
              API.private.logout();
              self.$router.replace("/login");
            }
            break;
          case "ERR_NOT_FOUND":
            self.isPageLoading = false;
            return; // do nothing
          default:
            self.showError({ message: e.response });
        }
      }
      self.isPageLoading = false;
    }
  },
  beforeMount: function() {
    this.loadData();
  },
  watch: {
    selectedSuit(newValue) {
      this.touchedFields.suit_id = newValue.id;
    }
  },
  notifications: {
    showError: {
      title: "Ошибка ",
      type: "error"
    },
    showSuccess: {
      title: "Данные сохранены",
      type: "success"
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style local>
.emptySelect .multiselect__content li:last-child {
  display: block !important;
}

.multiselect {
  min-height: auto !important;
}

.multiselect__tags,
.multiselect__input,
.multiselect__input:placeholder-shown,
.multiselect__tags-wrap,
.multiselect__single,
.option__img,
.multiselect__option,
.multiselect__option--highlight,
.multiselect__element,
.multiselect__content {
  background-color: #515b65;
  color: #e4e7ea;
  border: 0px solid;
  display: block !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: #38404a;
  color: #e4e7ea;
  border: 0px solid;
  display: block !important;
}

.multiselect--disabled {
  background-color: #38404a;
  color: #e4e7ea;
  border: 0px solid;
}

.multiselect--disabled .multiselect__select {
  display: none;
}

.option__desc {
  margin-left: 10px;
  word-wrap: break-word;
}

.option__title,
option__desc {
  font: inherit;
  display: block !important;
}
</style>
